.service-description {
  margin: 166px 200px 40px;
  position: relative;
}

.service-description-header h1 {
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
  color: #1e1e1e;
  margin-bottom: 50px;
  width: 50%;
}

.service-description-body {
  display: flex;
  gap: 81px;
}

.topnotch {
  margin-top: 26px;
}

.topnotch p {
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
  margin-bottom: 40px;
}

.topnotch a {
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
}

.service-description ul {
  display: flex;
  gap: 26px;
  list-style: none;
  background-color: #028eed;
  width: 90%;
  padding: 77px 100px;
  justify-content: space-between;
  position: relative;
  bottom: 120px;
  left: 180px;
}

.service-description ul li {
  text-align: center;
}

.service-description ul li h1 {
  color: white;
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
}

.service-description ul li p {
  color: white;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}

.about-us-container {
  display: flex;
  gap: 102px;
  align-items: center;
  padding-right: 193px;
}

.about-us h3 {
  color: #028eed;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 25%;
  margin-bottom: 12px;
}

.about-us h1 {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -5%;
  margin-bottom: 12px;
}

.about-us .para {
  margin: 0 0 48px;
}

.about-us .para p{
  margin: 0 0 36px;
}

.about-us p {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
}

.about-us button {
  background: linear-gradient(265.45deg, #01cbf9 -17.01%, #028eed 58.06%);
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  padding: 24px 72px;
  border-radius: 12px;
  border: none;
  color: white;
  text-transform: uppercase;
}

.what-we-do {
  background: #fbfbfb;
  padding: 120px 228px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* gap: 160px; */
  grid-row-gap: 100px;
  grid-column-gap: 200px;
}

.what-we-do img {
  padding: 35px 30px;
  /* background-color: #01cbf9; */
  background: #01cbf91a;
  border-radius: 12px;
  margin-bottom: 30px;
  animation-name: what-we-do-images;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  width: 100px;
  height: 100px;
}

.what-we-do h1 {
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -5%;
  font-weight: 700;
  margin-bottom: 12px;
}

.what-we-do p {
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
}

.need_a_repair {
  width: 100%;
  position: relative;
}

.need_a_repair img {
  width: 100%;
}

.need_a_repair .need_a_repair_cta {
  position: absolute;
  top: 53%;
  right: 190px;
}

.need_a_gadget {
  position: relative;
  bottom: 10px;
  width: 100%;
  /* height: 85vh; */
  padding: 130px 0 0 680px;
  background: #efefef;
  overflow: hidden;
  margin-bottom: 300px;
}

.need_a_gadget .need_a_gadget_cta {
  position: absolute;
  top: 33%;
  left: 400px;
}

@media screen and (max-width: 1024px) {
  .service-description {
    margin: 100px 20px 30px;
  }

  .service-description-header h1 {
    font-size: 24px;
    line-height: 36px;
    /* background: blue; */
    margin-bottom: 30px;
    width: 100%;
  }

  .service-description-body {
    /* flex-direction: column; */
    gap: 40px;
  }

  .topnotch p,
  .topnotch a {
    font-size: 16px;
    line-height: 21px;
  }

  .service-description ul {
    /* flex-direction: column; */
    gap: 20px;
    padding: 50px 40px;
    width: 100%;
    left: 0;
  }

  .service-description ul li h1 {
    font-size: 24px;
    line-height: 28px;
  }

  .service-description ul li p {
    font-size: 16px;
    line-height: 21px;
  }

  .about-us-container {
    flex-direction: flex;
    padding: 0;
    gap: 20px;
  }

  .about-us h1 {
    font-size: 24px;
    line-height: 36px;
  }

  .about-us p {
    font-size: 16px;
    line-height: 21px;
  }

  .about-us-container > div > img {
    width: 500px;
    object-fit: cover;
    /* background-color: red;  */
  }

  /* .about-us p {
    font-size: 18px;
    line-height: 28px;
  } */

  .about-us button {
    padding: 20px 40px;
    font-size: 14px;
  }

  .what-we-do {
    padding: 60px 20px;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 80px;
    grid-column-gap: 50px;
    margin-top: 30px;
  }

  .what-we-do img {
    padding: 20px;
  }

  .what-we-do h1 {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -5%;
    font-weight: 700;
  }

  .what-we-do p {
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
  }

  .need_a_repair .need_a_repair_cta {
    top: 50%;
    right: 20px;
  }

  .need_a_gadget {
    padding: 80px 0 0 20px;
    margin-bottom: 100px;
  }

  .need_a_gadget .need_a_gadget_cta {
    top: 30%;
    left: 20px;
  }
}

@media screen and (max-width: 430px) {
  .service-description {
    margin: 48px 24px 30px;
    /* width: 100%; */
  }

  .service-description-header h1 {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 30px;
    width: 100%;
  }

  .service-description-body {
    flex-direction: column;
    gap: 25px;
    /* width: 100%; */
  }

  .service-description-body img {
    width: 100%;
  }

  .topnotch {
    margin-bottom: 45px;
    margin-top: 20px;
  }

  .topnotch p,
  .topnotch a {
    font-size: 16px;
    line-height: 21px;
  }

  .service-description ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;
    padding: 70px 60px;
    width: 100%;
    left: 0;
    bottom: 0;
  }

  .service-description ul li h1 {
    font-size: 29px;
    line-height: 36px;
  }

  .service-description ul li p {
    font-size: 9px;
    line-height: 11px;
  }

  .about-us-container {
    flex-direction: column;
  }

  .about-us {
    padding: 24px;
  }

  .about-us h1 {
    font-size: 20px;
    line-height: 24px;
  }

  .about-us h3 {
    font-size: 12px;
    font-weight: 700;
    line-height: 14.63px;
    letter-spacing: 0.25em;
  }

  .about-us .para {
    margin: 0 0 24px;
  }
  
  .about-us .para p{
    margin: 0 0 24px;
  }

  .about-us p {
    font-size: 14px;
    font-weight: 400;
    line-height: 22.4px;
  }

  .about-us-container > div > img {
    width: 100%;
    object-fit: cover;
  }

  .about-us button {
    padding: 20px 40px;
    font-size: 14px;
  }

  .what-we-do {
    padding: 36px 24px;
    margin: 24px;
    border-radius: 12px;
    grid-template-columns: 1fr;
    grid-row-gap: 48px;
    margin-top: 30px;
  }

  .what-we-do img {
    padding: 13px;
    width: 48px;
    height: 48px;
    border-radius: 6px;
  }

  .what-we-do h1 {
    font-size: 20px;
    line-height: 24px;
  }

  .what-we-do p {
    font-size: 16px;
    line-height: 25px;
  }

  .need_a_repair .need_a_repair_cta {
    position: static;
    /* top: 50%;
    right: 20px; */
  }

  .need_a_gadget {
    padding: 80px 0 0 0;
    margin-bottom: 0;
  }

  .need_a_gadget img {
    width: 100%;
  }

  .need_a_gadget .need_a_gadget_cta {
    position: static;
    top: 30%;
    left: 20px;
  }
}
