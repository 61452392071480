.mother-container {
  height: 100vh;
  position: relative;
  background: linear-gradient(
      177.8deg,
      rgba(0, 0, 0, 0) 11.97%,
      #000000 127.17%
    ),
    url("../../assets/images/hero-img.svg");
  background-repeat: no-repeat;
}

.welcome {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 55%;
  text-align: center;
}

.welcome h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 64px;
  line-height: 78px;
  color: white;
}

.welcome h3 {
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.1em;
  color: white;
  font-weight: 400;
  text-transform: uppercase;
}

.welcome h1 span {
  color: #028eed;
}

.welcome p {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 32px;
  color: white;
  padding: 30px;
}

.welcome button {
  padding: 10px 52px;
  border: 1px solid white;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  font-size: 12px;
  line-height: 32px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  color: white;
  text-transform: uppercase;
  backdrop-filter: blur(5px);
}

@media screen and (max-width: 1024px) {
  .mother-container {
    background-size: cover;
    background-position: center;
    height: 750px;
  }

  .welcome {
    top: 50%;
    width: 70%;
    padding: 0 20px;
  }

  .welcome h1 {
    font-size: 48px;
    line-height: 60px;
  }

  .welcome h3 {
    font-size: 18px;
    line-height: 28px;
  }

  .welcome p {
    font-size: 18px;
    line-height: 28px;
    padding: 20px;
  }

  .welcome button {
    padding: 8px 40px;
    font-size: 14px;
  }
}

@media screen and (max-width: 430px) {
  .mother-container {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
 
  .welcome {
    width: 100%;
  } 

  .welcome {
    top: 60%;
    padding: 0;
  }

  .welcome h1 {
    font-size: 32px;
    line-height: 39.01px;
  }

  .welcome h3 {
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.1em;
    font-weight: 400;
    text-transform: uppercase;
  }

  .welcome p {
    font-size: 16px;
    line-height: 25px;
    padding: 24px 0 72px;
  }
}
