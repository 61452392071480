.customer_testimonials {
  margin: 190px 380px;
  text-align: center;
}

.customer_testimonials h1 {
  font-family: Montserrat;
  font-size: 36px;
  font-weight: 600;
  line-height: 43.88px;
  text-align: center;
  margin-bottom: 30px;
}

.testimonial img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 30px;
}

.testimonial p {
  font-size: 20px;
  font-weight: 300;
  line-height: 32px;
  margin-bottom: 30px;
}

.testimonial h3 {
  font-size: 20px;
  font-weight: 600;
  line-height: 24.38px;
  margin-bottom: 8px;
}

.testimonial h5 {
  font-size: 12px;
  font-weight: 500;
  line-height: 14.63px;
  margin-bottom: 30px;
}

.pagination-dots {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.dot {
  height: 7px;
  width: 7px;
  margin: 0 5px;
  background: #d9d9d9;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.dot.active {
  background: #028eed;
}

.testimonial-container {
  transition: opacity 0.5s ease-in-out;
}

.testimonial-container.fade {
  opacity: 0;
}

@media screen and (max-width: 1024px) {
  .customer_testimonials {
    margin: 190px 50px;
    text-align: center;
  }

  .customer_testimonials h1 {
    font-size: 24px;
    line-height: 28px;
  }

  .testimonial p {
    font-size: 16px;
    line-height: 21px;
  }

  .testimonial h3 {
    font-size: 18px;
    line-height: 24px;
  }

  .testimonial h5 {
    font-size: 10px;
    line-height: 14px;
  }
}


@media screen and (max-width: 430px) {
  .customer_testimonials {
    margin: 48px 24px;
  }

  .customer_testimonials h1 {
    font-size: 20px;
    line-height: 24px;
  }

  .testimonial p {
    font-size: 14px;
    line-height: 22px;
  }

  .testimonial h3 {
    font-size: 16px;
    line-height: 19.5px;
  }

  .testimonial h5 {
    font-size: 12px;
    line-height: 14px;
  }
}