.navigation-container .nav_children {
  display: flex;
  justify-content: space-between;
  padding: 24px 99px;
  align-items: center;
  /* background-color: #fff; */
}

.navigation-items ul {
  display: flex;
  gap: 26px;
  list-style: none;
}

.navigation-items ul li a,
.reglog a {
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 32px;
  color: white;
  font-weight: 400;
}

.navigation-items ul li a.active,
.reglog a.active {
  font-weight: 800;
}

.reglog {
  display: flex;
  gap: 24px;
}

.reglog .reg {
  border: 1px solid white;
  border-radius: 8px;
  padding: 10px 25px;
}

.reglog .log {
  border: 1px solid white;
  border-radius: 8px;
  padding: 10px 25px;
  background: white;
  color: black;
}

.mobile_logo,
.menu_icon {
  display: none;
}

.web_logo {
  display: block;
}

@media screen and (max-width: 1024px) {
  .navigation-container .nav_children {
    padding: 20px 50px;
  }

  .navigation-items ul {
    gap: 20px;
  }

  .navigation-items ul li a,
  .reglog a {
    font-size: 11px;
    line-height: 28px;
  }

  .reglog {
    gap: 16px;
  }

  .reglog .reg,
  .reglog .log {
    padding: 8px 20px;
  }
}

@media screen and (max-width: 430px) {
  .navigation-container .nav_children {
    display: flex;
    justify-content: space-between;
    padding: 24px 20px;
  }

  .navigation-container.nav_visible {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 999;
    background: rgba(0, 0, 0, 0.6);
    /* backdrop-filter: blur(5px); */
  }

  .navigation-container.nav_visible .nav_children {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 80%;
    padding: 48px 48px 0;
    align-items: normal;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    background-color: #fff;
    display: block;
  }

  .menu_logo_cancel {
    display: flex;
    align-items: center;
    /* width: 200px; */
    width: 100%;
    justify-content: space-between;
  }

  .navigation-container.nav_visible .navigation-items,
  .navigation-container.nav_visible .reglog,
  .mobile_logo,
  .menu_icon {
    display: block;
  }

  .navigation-container.nav_visible .menu_icon {
    display: none;
  }

  .navigation-container.nav_visible .navigation-items ul,
  .navigation-container.nav_visible .reglog {
    margin-top: 50px;
    flex-direction: column;
    gap: 12px;
    padding: 0 0 10px;
    border-bottom: 0.25px solid rgba(200, 200, 200, 1);
  }

  .navigation-container.nav_visible .reglog {
    border-bottom: none;
    gap: 8px;
  }

  .navigation-container.nav_visible .reglog {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
  }

  .navigation-items ul li a,
  .reglog a {
    /* padding: 12px; */
    width: 100%;
    color: #0b1223;
  }

  .navigation-items ul li {
    border-radius: 8px;
    padding: 12px;
  }

  .navigation-items ul li:has(a.active) {
    background: rgba(241, 241, 241, 1);
    border-radius: 8px;
    padding: 12px;
  }

  .navigation-items ul li a.active,
  .reglog a.active {
    font-weight: 400;
  }

  .reglog .log {
    background: linear-gradient(263.75deg, #01cbf9 -5.88%, #028eed 22.9%);
    border: none;
    color: #fff;
  }

  .reglog .reg {
    border: 0.5px solid rgba(11, 18, 35, 1);
    border-radius: 8px;
    padding: 10px 25px;
    background: white;
    color: black;
  }

  .navigation-container.nav_visible .reglog a {
    text-align: center;
  }

  .navigation-items,
  .reglog,
  .web_logo {
    display: none;
  }
}
