.about_section_1 {
  padding: 160px 210px 260px;
  display: flex;
  gap: 50px;
}

.about_section_1 .section_1_text_img h3 {
  font-size: 32px;
  font-weight: 700;
  line-height: 39.01px;
  letter-spacing: -0.05em;
  color: #028eed;
  margin-bottom: 40px;
}

.about_section_1 .section_1_text h4 {
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 34px;
}

.about_section_1 .section_1_text p {
  font-size: 20px;
  font-weight: 400;
  line-height: 36px;
}

.about_section_2 {
  padding: 0 200px 260px;
  gap: 50px;
}

.about_section_2 h1 {
  font-size: 32px;
  font-weight: 700;
  line-height: 39.01px;
  letter-spacing: -0.05em;
  color: #028eed;
  margin-bottom: 40px;
}

.about_section_2 .team {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-row-gap: 130px;
  grid-column-gap: 15px;
}

.about_section_2 .team .team_member img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.about_section_2 .team .team_member h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  padding: 20px 0 1px 14px;
}

.about_section_2 .team .team_member p {
  font-size: 14px;
  font-weight: 400;
  line-height: 17.07px;
  padding: 0 0 1px 14px;
}

.about_section_3 {
  padding: 0 0 100px;
  position: relative;
}

.about_section_3 img {
  width: 100%;
}

.about_section_3 .about_cta_card {
  position: absolute;
  bottom: 25%;
  right: 300px;
}

@media screen and (max-width: 1024px) {
  .about_section_1 {
    padding: 100px 20px 200px;
    gap: 20px;
  }

  .about_section_1 .section_1_text_img h3 {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 20px;
  }

  .about_section_1 .section_1_text h4 {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 17px;
  }

  .about_section_1 .section_1_text p {
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
  }

  .about_section_2 {
    padding: 0 20px 160px;
    gap: 20px;
  }

  .about_section_2 h1 {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 20px;
  }

  .about_section_2 .team {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 150px;
    grid-column-gap: 15px;
  }

  .about_section_3 .about_cta_card {
    position: absolute;
    bottom: 35%;
    right: 100px;
  }
}

@media screen and (max-width: 430px) {
  .about_section_1 {
    padding: 48px 24px 190px;
    gap: 20px;
    flex-direction: column;
  }

  .about_section_1 .section_1_text_img h3 {
    font-size: 24px;
    line-height: 29.26px;
  }

  .about_section_1 .section_1_text_img img {
    width: 100%;
  }

  .about_section_1 .section_1_text h4 {
    font-size: 14px;
    line-height: 22px;
  }

  .about_section_1 .section_1_text p {
    font-size: 14px;
    line-height: 22px;
  }

  .about_section_2 h1 {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 30px;
  }

  .about_section_2 .team {
    grid-row-gap: 87px;
    grid-column-gap: 10px;
  }
  

  .about_section_2 .team .team_member h3 {
    font-size: 14px;
    line-height: 22px;
    padding: 14px 0 1px 9px;
  }
  
  .about_section_2 .team .team_member p {
    font-size: 10px;
    line-height: 11px;
    padding: 0 0 1px 9px;
  }

  .about_section_3 .about_cta_card {
    position: static;
  }
}

